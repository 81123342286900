// import data from './data'

import React, { Component } from "react"

import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom"

// import createHistory from "history/createBrowserHistory"

import { graphql } from "react-apollo"
import gql from "graphql-tag"

import styled from "styled-components"

import { createGlobalStyle } from "styled-components"

import { user, userId } from "./config"
import { findKeyValue } from "./utilities/data"

import friendlyUrl from "friendly-url"

import ProjectOverview from "./ProjectOverview"
import Project from "./Project"
import Info from "./Info"

const div = styled.div

// const history = createHistory()

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Courier New", Courier, monospace;
    font-size: 12px;
    color: black;
    width: 100%;
  }
  a {
    text-decoration: none;
    color: black;
  }
`

if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
  window.document.body.style.fontSize = "15px"
}

// import projects from './projects'
export const findProjectBySlug = (projects, slug) =>
  projects.find(
    (project) =>
      friendlyUrl(project.title) === slug ||
      (friendlyUrl(project.title) === "" && slug === "being-time")
  )
const projectTitleBySlug = (projects, slug) =>
  projects.find(
    (project) =>
      friendlyUrl(project.title) === slug ||
      (friendlyUrl(project.title) === "" && slug === "being-time")
  ).title
// import information from './information'

const Wrapper = div`
  position: relative;
  max-width: calc(3600px + 40vw);
  margin-left: auto;
  margin-right: auto;
  padding: 2em 1em;

  @media (max-width: 721px) {
    padding: 3em 1em;
  }
`

export const Nav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 1.25em;
  z-index: 5;
  ${(props) =>
    props.fixed === true
      ? `
    position: fixed;
    left: 1em;
    @media(max-width: 650px) {
      margin-top: -1.5em;
    }
  `
      : null} ${(props) =>
        props.vertical === true
          ? `
    @media(max-width: 721px) {
      position: absolute;
    }
  `
          : null};
`

const Spacer = div`
  height: 1.5em;
`

export const NavItem = styled.li`
  display: block;
  line-height: 1.6em;
  margin-right: .5em;

  ${(props) =>
    props.spaceTop
      ? `
    marginTop: 1em;
  `
      : null}

  ${(props) =>
    props.spaceBottom
      ? `
    marginBottom: 2em;
  `
      : null}

  ${(props) =>
    !props.static
      ? `
    & a:hover {
      opacity: .7;
    }
  `
      : null}

  & a {
    padding: .25em 0;
  }
`

export const Back = styled.span`
  padding: 0.5em 0;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

/*


    { [...projects].sort((a,b) => {
      const an = findKeyValue(a.keyValues, 'sortOrder')
      const bn = findKeyValue(b.keyValues, 'sortOrder')
      if(an > bn) {
        return 1;
      }
      if(an < bn) {
        return -1;
      }
      return 0;
    }).filter(project => (project.title != 'cv' && project.title != 'contact')).map(project => <NavItem key={project.id}><Link to={friendlyUrl(project.title) != ''? friendlyUrl(project.title) : 'being-time'}>{ project.title }</Link></NavItem>) }


*/
const Menu = ({ projects }) => (
  <Nav>
    <NavItem spaceBottom>carine weve</NavItem>
    <NavItem>&nbsp;</NavItem>

    {[...projects]
      /* .sort((a, b) => {
        const an = findKeyValue(a.keyValues, "sortOrder")
        const bn = findKeyValue(b.keyValues, "sortOrder")
        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      */
      .filter(
        (project) =>
          project.title != "cv" &&
          project.title != "contact" &&
          project.title != "about"
      )
      .map((project) => (
        <NavItem key={project.id}>
          <Link
            to={
              friendlyUrl(project.title) != ""
                ? friendlyUrl(project.title)
                : "being-time"
            }
          >
            {project.title}
          </Link>
        </NavItem>
      ))}

    <NavItem>&nbsp;</NavItem>
    <NavItem spaceTop>
      <Link to="/cv">cv</Link>
    </NavItem>
    <NavItem>
      <Link to="/about">about</Link>
    </NavItem>
    <NavItem>
      <Link to="/contact">contact</Link>
    </NavItem>
  </Nav>
)

const BackAndProjectTitle = () => {

  let history = window.history.length;
  let navigate = useNavigate();

  return (
    <Nav>
      <NavItem>
        {history.length > 1 ? (
          <Back onClick={navigate(-1)}>&lt; home</Back>
        ) : (
          <Back>
            <Link to="/">carine weve</Link>
          </Back>
        )}
      </NavItem>
    </Nav>
  )
}

window.h = history

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      error: null,
      loading: false,
    }
    // this.checkLoaded = this.checkLoaded.bind(this)
    // setTimeout(this.checkLoaded, 10)
  }
  checkLoaded() {
    // if (this.props.data.loading === true) {
    this.loadStaticData()
    // }
  }
  componentDidMount() {
    // this.loadStaticData()
  }
  loadStaticData() {
    this.setState({
      loading: true,
    })
    const request = new XMLHttpRequest()
    request.open("GET", "/data.json", true)
    const self = this
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        const data = JSON.parse(request.responseText)
        self.setState({
          data,
          loading: false,
        })
      } else {
        // We reached our target server, but it returned an error
        self.setState({
          error: "loading error",
          loading: false,
        })
      }
    }

    request.onerror = function () {
      // There was a connection error of some sort
    }

    request.send()
  }
  render() {
    const data = this.state.data ? this.state.data.data : this.props.data
    // convert allSlides to projects
    let projects = []
    let information = []
    let imageProxies = []
    if ("allAlbums" in data) {
      projects = data.allAlbums
      console.log(projects)
      // split allSlides into chunks based on the tag 'project'
      // in wezen zou dit met een reducer kunnen?
      // loop, wanneer ik op een 'project' stuit, een nieuwe tmp array en de oude tmp array pushen naar de 'projects' array
      /*
      for (let i=0; i<slides.length; i++) {
        const slide = slides[i]
        if (slide.tags.find(tag => tag.name === 'info')) {
          information = slide.description.split('<br>').join('\n').split('\n\n').map(paragraph => ({paragraph}))
        } else if (slide.tags.find(tag => tag.name === 'project')) {
          const project = {
            title: slide.title,
            description: slide.description,
            slug: friendlyUrl(slide.title),
            slides: [{...slide}],
            id: slide.id
          }
          projects.push(project)
        } else if (projects.length > 0) {
          const project = projects[projects.length-1]
          // if (project.slides.length<5 && slide.width) {
            projects[projects.length-1].slides.push(slide)
          // }
        }
      }
      */
    }
    console.log(projects.length)
    if (projects.length === 0) {
      return (
        <Wrapper className="here loading">
          <GlobalStyle />
          <Nav>
            <NavItem>...loading</NavItem>
            {1 === 5 && <pre>{JSON.stringify(this.state, null, 2)}</pre>}
          </Nav>
        </Wrapper>
      )
    }
    return (
      <Router>
        <Wrapper className="here">
          <GlobalStyle />
          <Routes>
            <Route
              path="/"
              element={<Menu projects={projects} />}
            />
            <Route path="/about" element={<BackAndProjectTitle />} />
            <Route path="/cv" element={<BackAndProjectTitle />} />
            <Route path="/contact" element={<BackAndProjectTitle />} />
          </Routes>

          <Routes>
            <Route
              path="/about"
              element={<Info data={findProjectBySlug(projects, "about").description} />
              }
            />
            <Route
              path="/cv"
              element={<Info
                data={findProjectBySlug(projects, "cv").description}
                allowSmall={true}
              />
              }
            />
            <Route
              path="/contact"
              element={<Info
                data={findProjectBySlug(projects, "contact").description}
                left={true}
              />
              }
            />
            <Route
              path="/:slug"
              element={<Project
                history={window.history}
                projects={projects}
                imageProxies={imageProxies}
              />
              }
            />
          </Routes>
        </Wrapper>
      </Router>
    )
  }
}

const slidesQuery = gql`
  query allAlbums($user: String!) {
    allAlbums: albums(
      filter: {
        user: { username: { equalTo: $user } }
        hidden: { equalTo: false }
      }
      orderBy: POS_DESC
    ) {
      id
      title
      description
      keyValues {
        id
        key {
          name
        }
        value
      }
      slides(orderBy: POS_ASC, filter: { hidden: { equalTo: false } }) {
        id
        order: pos
        file: image {
          url
        }
        width
        height
        title
        description
        tags {
          id
          name
        }
        keyValues {
          id
          key {
            name
          }
          value
        }
      }
    }
  }
`

const AppWithData = graphql(slidesQuery, {
  options: (props) => ({
    variables: {
      user: user,
    },
    fetchPolicy: "cache-and-network",
  }),
})(App)

export default AppWithData
