import "babel-polyfill"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { createGlobalStyle } from "styled-components"
import DataProvider from "./DataProvider"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    width: 100%;
    padding: 0;
    margin: 0;
    user-select: none;
  }
`

ReactDOM.render(
  <DataProvider>
    <div>
      <GlobalStyle />
      <App />
    </div>
  </DataProvider>,
  document.getElementById("root")
)
