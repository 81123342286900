import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Gallery } from "./Project"

const div = styled.div

const Container = div`
  margin-left: ${props => (props.left === true ? "0px" : "auto")};
  margin-right: auto;
  margin-top: 3.5em;
  font-size: 1.15em;
  & a:hover {
    opacity: .7;
  }
  ${props =>
    props.allowSmall === true
      ? `
    @media(max-width: 650px) {
      font-size: 1em;
    }
  `
      : null}
`

const Textxt = div`
  max-width: 43em;
`

const Paragraph = styled.p`
  margin: 1em 0;
  max-width: 32em;
  &:first-child {
    margin-top: 0;
  }
`

export default ({ data, allowSmall, left }) => (
  <Gallery left={left}>
    <Container allowSmall={allowSmall} left={left}>
      <Textxt>
        <ReactMarkdown>{data}</ReactMarkdown>
      </Textxt>
    </Container>
  </Gallery>
)
