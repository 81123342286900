import React, { Component } from "react"
import LazyLoad from "react-lazyload"
import styled, { keyframes } from "styled-components"
import imageSize from "../../utilities/imageSize"

const div = styled.div

export const composedUrl = (_, url, width, height) =>
  `https://images.weserv.nl/?url=${url.split("https://").join("")}&w=${width}`

/*
export const composedUrl = (imageProxies, url, width, height) => {
  const proxyUrl = imageProxies[0].url
  const urlNoProtocol = url.split("https://").join("")
  return proxyUrl
    .split("[url]")
    .join(url)
    .split("[urlNoProtocol]")
    .join(urlNoProtocol)
    .split("[width]")
    .join(width)
    .split("[height]")
    .join(height)
}
*/

class FadeImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }
  componentDidMount() {
    const self = this
    const img = new Image()
    img.onload = function () {
      self.setState({ loaded: true })
    }
    img.src = this.props.src
  }
  render() {
    if (this.state.loaded) {
      return <BackgroundImage src={this.props.src} loaded={this.state.loaded} />
    } else {
      return null
    }
  }
}

const opacity = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
`

const BackgroundImage = div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .3s ease;
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.src});
  animation: ${opacity} .7s ease forwards;
`

const Container = div`
  position: relative;

  &:hover .caption {
    opacity: 1;
  }

  user-select: none;

  transition: transform .15s ease;

  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;

`

const ImageContainer = div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

const wh = (width, height) => ({ width, height })

export default class LazyImage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      imageUrl,
      caption = "",
      width,
      height,
      style,
      imageProxies,
      force,
      ...rest
    } = this.props
    const mySize = imageSize({ width, height })
    const myUrl = composedUrl(
      imageProxies,
      imageUrl,
      mySize.width,
      mySize.height
    )
    const options = {
      onMouseDown: (e) => void 0,
    }
    return (
      <Container style={{ ...style, ...wh(width, height) }} {...rest}>
        {force ? (
          <ImageContainer>
            {myUrl.length > 10 && <FadeImage src={myUrl} />}
          </ImageContainer>
        ) : (
          <LazyLoad once height={height} offset={400}>
            <ImageContainer>
              {myUrl.length > 10 && <FadeImage src={myUrl} />}
            </ImageContainer>
          </LazyLoad>
        )}
      </Container>
    )
  }
}
