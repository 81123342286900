import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link, useNavigate, useParams } from "react-router-dom"
import ReactMarkdown from "react-markdown"

import { findKeyValue } from "./utilities/data"

import styled from "styled-components"

import { Nav, NavItem, Back, findProjectBySlug } from "./App"

import Image from "./components/Image/Image"

import { letterboxOrCrop } from "./utilities/imageSize"

const div = styled.div

// import keydown, { Keys } from 'react-keydown'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

// const ScrollToTopComponent = withRouter(ScrollToTop)

const BackAndProjectTitle = ({ history, fixed, vertical }) => {
  const navigate = useNavigate()
  return (
  <Nav fixed={fixed} vertical={vertical}>
    <NavItem>
      {history.length > 1 ? (
        <Back onClick={() => navigate(-1)}>&lt; home</Back>
      ) : (
        <Link to="/">&lt; home</Link>
      )}
    </NavItem>
  </Nav>
)}

export const Gallery = div`
  max-width: calc(100vw - 300px);
  @media(max-width: 790px) {
    max-width: calc(30vw + 200px);
  }
  margin-left: ${props => (props.left === true ? "0" : "auto")};
  margin-right: auto;
  margin-top: ${props => (props.space === true ? `0` : "0")};
  padding-top: ${props => (props.space === true ? `2em` : "0")};
  @media(max-width: 650px) {
    max-width: 100vw;
    margin-top: 2em;
  }
`

const Title = styled.span`
  max-width: 35em;
  font-weight: bold;
  ${props =>
    props.fixed
      ? `
  position: fixed;
  top: 4em;
  `
      : null};
`

const Year = styled.span`
  font-weight: regular;
`

const Spec = div`
`

const Specs = div`
  margin-top: 1em;
  max-width: ${props => props.maxWidth}px;
  color: #777;
`
// ricky: height: calc(100vw * 0.66);

/*
  height: calc(100vh - 14em);
  max-height: calc(100vh - 14em);
*/

// calc(100vw * 0.4);

/*
  ${props => props.square? `
    height: calc(100vmin - 9em);
    max-height: calc(100vmin - 9em);
  ` : null}
*/
export const Canvas = div`
  margin-left: 0;
  margin-right: 0;
  height: calc(100vw * 0.4);
  max-height: calc(100vh - 14em);

  @media (max-height: 660px) {
    min-height: calc(0.66 * 100vmin);
  }
  @media (max-width: 660px) {
    height: calc(0.66 * 100vmin);
  }
  ${props => (props.horizontal ? `white-space: nowrap;` : null)}

    ${props =>
      props.square
        ? `
      width: 100vw;
      position: absolute;
      left: 0;
      pointer-events: none;
  `
        : null}

`

const NavigatorButton = styled.span`
  cursor: pointer;
  padding: 0.5em 0;
`

const Text = styled.span`
  padding: 0.5em 0;
`

const Controls = div`
  margin: 0;
  margin-top: .15em;
`

const VerticalSpacer = div`
  height: 2em;
`

const TextButton = div`
  position: ${props => (props.horizontal === true ? "fixed" : "absolute")};
  @media(min-width: 650px) {
    position: fixed;
  }
  left: 2.2em;
  top: ${props => 40 + props.top}px;
  font-size: 1.25em;
  cursor: pointer;
  z-index: 4;
  font-weight: bold;
  @media(max-width: 650px) {
    right: 1.5em;
    top: ${props => (props.horizontal === true ? "2.5" : "-1.5")}em;
    margin-top: 4px;
    left: auto;
  }
  & a:hover {
    opacity: .7;
  }
`

const TextContainer = div`
  width: 100%;
`

const ProjectText = ({
  text,
  horizontal,
  vertical,
  showText,
  textToggle,
  top
}) => (
  <TextButton
    top={top}
    horizontal={horizontal}
    onClick={textToggle}
    style={{ color: "#E00000" }}
  >
    {showText ? "return" : "text"}
  </TextButton>
)

const TheProjectText = div`
  position: fixed;
  top: 8em;
  @media(max-width: 650px) {
    top: 6em;
    font-size: 1em;
  }
  bottom: 0;
  font-size: 1.15em;
  white-space: normal;
  max-width: 45em;
  padding-right: 2em;
  padding-bottom: 2em;
  overflow-y: auto;
  user-select: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  & a:hover {
    opacity: .7;
  }
  ${props =>
    props.allowSmall === true
      ? `
    @media(max-width: 650px) {
      font-size: 1em;
    }
  `
      : null}
`

const WhiteContainer = div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
`

const White = div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${props => props.width}px;
  background-color: white;
  z-index: 1;
`

const ProjectWrapper = ({projects, ...rest}) => {
  const { slug } = useParams()
  const project= findProjectBySlug(projects, slug)

  return <Project {...rest} project={project} />

}

class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      width: 0,
      height: 0,
      left: 0,
      showText: false
    }
    this.setCanvasDimensions = this.setCanvasDimensions.bind(this)
    this.dealWithKeyboard = this.dealWithKeyboard.bind(this)
  }
  // @keydown( Keys.RIGHT )
  nextSlide() {
    const { activeSlide } = this.state
    const { project } = this.props
    this.setState({ activeSlide: (activeSlide + 1) % project.slides.length })
  }
  // @keydown( Keys.LEFT )
  previousSlide() {
    const { activeSlide } = this.state
    const { project } = this.props
    if (activeSlide > 0) {
      this.setState({ activeSlide: activeSlide - 1 })
    } else if (activeSlide === 0) {
      this.setState({ activeSlide: project.slides.length - 1 })
    }
  }
  textToggle() {
    this.setState({
      showText: !this.state.showText
    })
  }
  dealWithKeyboard(e) {
    switch (e.keyCode) {
      case 37: // left key pressed
        this.previousSlide()
        break
      case 38: // up key pressed
        break
      case 39: // right key pressed
        this.nextSlide()
        break
      case 40: // down key pressed
        break
    }
  }
  getCanvasDimensions() {
    const el = ReactDOM.findDOMNode(this.el)
    const rect = el.getBoundingClientRect()
    return { width: rect.width, height: rect.height, left: rect.left }
  }
  setCanvasDimensions() {
    const { width, height, left } = this.getCanvasDimensions()
    this.setState({
      width,
      height,
      left
    })
  }
  componentDidMount() {
    this.setCanvasDimensions()
    window.addEventListener("resize", this.setCanvasDimensions, false)
    window.addEventListener("keydown", this.dealWithKeyboard, false)
    window.scrollTo(0, 0)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.setCanvasDimensions, false)
    window.removeEventListener("keydown", this.dealWithKeyboard, false)
  }
  render() {
    const { project, imageProxies } = this.props
    // what is the design of this project? one-by-one, vertical of horizontal?
    const design = findKeyValue(project.keyValues, "design")
    const horizontal = design === "horizontal"
    const vertical = design === "vertical"
    const oneByOne =
      design === "one-by-one" ||
      (!horizontal && !vertical) ||
      design === "one-by-one-square"
    const square = design === "one-by-one-square"
    console.log(design)
    const { activeSlide, width, height, left, showText } = this.state
    const slide = project.slides[activeSlide]
    const preloadSlide =
      activeSlide < project.slides.length
        ? project.slides[activeSlide + 1]
        : null
    let dimensions, preloadDimensions
    if (square) {
      dimensions = letterboxOrCrop(
        { width, height },
        { width: 400, height: 300 }
      )
      dimensions = letterboxOrCrop(
        { width: dimensions.width, height: dimensions.width },
        { ...slide }
      )
      preloadDimensions = letterboxOrCrop(
        { width, height },
        { width: 400, height: 300 }
      )
      preloadDimensions = letterboxOrCrop(
        { width: preloadDimensions.width, height: preloadDimensions.width },
        { ...preloadSlide }
      )
    } else {
      dimensions = letterboxOrCrop({ width, height }, { ...slide })
      preloadDimensions = letterboxOrCrop(
        { width, height },
        { ...preloadSlide }
      )
    }
    const projectText = project.description
    const hideProject = showText
    return (
      <div style={{ position: "relative" }}>
        <BackAndProjectTitle
          history={this.props.history}
          fixed={vertical || horizontal}
          vertical={vertical}
        />
        <Gallery onKeyPress={this.key} space={horizontal || vertical}>
          {horizontal && <White width={left} />}
          {1 === 0 && <Title fixed={showText}>{project.title}</Title>}
          <Canvas
            ref={el => (this.el = el)}
            horizontal={horizontal}
            vertical={vertical}
            square={square}
          >
            {oneByOne && !square && (
              <div>
                <Image
                  imageProxies={imageProxies}
                  key={slide.id}
                  style={{ cursor: "pointer" }}
                  width={dimensions.width}
                  height={dimensions.height}
                  imageUrl={slide.file.url}
                  onClick={() => this.nextSlide()}
                />
              </div>
            )}

            {oneByOne &&
              activeSlide < project.slides.length - 1 &&
              activeSlide > 0 && (
                <Image
                  force
                  imageProxies={imageProxies}
                  key={project.slides[activeSlide - 1].id}
                  style={{
                    position: "fixed",
                    opacity: 0.4,
                    pointerEvents: "none",
                    left: "-120vw"
                  }}
                  width={preloadDimensions.width}
                  height={preloadDimensions.height}
                  imageUrl={preloadSlide.file.url}
                  onClick={() => this.nextSlide()}
                />
              )}

            {horizontal &&
              project.slides.map(slide => (
                <Image
                  imageProxies={imageProxies}
                  key={slide.id}
                  style={{
                    display: "inline-block",
                    marginRight: "1em",
                    visibility: hideProject ? "hidden" : "visible"
                  }}
                  width={(dimensions.height / slide.height) * slide.width}
                  height={dimensions.height}
                  imageUrl={slide.file.url}
                />
              ))}
            {(horizontal || vertical) && projectText && (
              <TextContainer>
                <ProjectText
                  top={height / 2}
                  height={height}
                  text={projectText}
                  horizontal={horizontal}
                  vertical={vertical}
                  showText={showText}
                  textToggle={() => this.textToggle()}
                />
                {showText && (
                  <TheProjectText>
                    <ReactMarkdown>{projectText}</ReactMarkdown>
                  </TheProjectText>
                )}
              </TextContainer>
            )}
            {vertical &&
              project.slides.map(slide => (
                <Image
                  imageProxies={imageProxies}
                  key={slide.id}
                  style={{
                    marginBottom: "2em",
                    visibility: hideProject ? "hidden" : "visible"
                  }}
                  width={dimensions.width}
                  height={(dimensions.width / slide.width) * slide.height}
                  imageUrl={slide.file.url}
                />
              ))}
            {vertical && <VerticalSpacer />}
          </Canvas>
          {square && (
            <Image
              imageProxies={imageProxies}
              key={slide.id}
              style={{ cursor: "pointer" }}
              width={dimensions.width}
              height={dimensions.height}
              imageUrl={slide.file.url}
              onClick={() => this.nextSlide()}
            />
          )}
          {preloadSlide && (
            <Image
              imageProxies={imageProxies}
              style={{ display: "none" }}
              width={preloadDimensions.width}
              height={preloadDimensions.height}
              imageUrl={preloadSlide.file.url}
            />
          )}
          {oneByOne && (
            <Controls>
              <NavigatorButton onClick={() => this.previousSlide()}>
                &lt;
              </NavigatorButton>
              <Text>{` ${activeSlide + 1}/${project.slides.length} `}</Text>
              <NavigatorButton onClick={() => this.nextSlide()}>
                &gt;
              </NavigatorButton>
            </Controls>
          )}
          {oneByOne && (
            <Specs maxWidth={dimensions.width}>
              <Title>{slide.title}</Title>{" "}
              <Year>{findKeyValue(slide.keyValues, "year")}</Year>
              <br />
              <Spec>{findKeyValue(slide.keyValues, "technique")}</Spec>
              {slide.description && <Spec>{slide.description}</Spec>}
              <Spec>{findKeyValue(slide.keyValues, "dimensions")}</Spec>
            </Specs>
          )}
          {!horizontal && !vertical && <Title>{project.description}</Title>}
        </Gallery>
      </div>
    )
  }
}

export default ProjectWrapper