import React from 'react'

import {
  Link
} from 'react-router-dom'

import friendlyUrl from 'friendly-url'

import Image from './components/Image/Image'

import styled from 'styled-components'
const div = styled.div

const Container = div`
  margin-top: 3.5em;
  overflow-x: hidden;
  margin-right: -2em;
  max-width: calc(100vw - 1em);
`

const Project = div`
  margin: 4em 0;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    opacity: .7;
  }
  transition: opacity .3s ease;
`

const Thumbnails = div`
  white-space: nowrap;
  overflow-x: visible;
`

const Thumbnail = div`
  display: inline-block;
  margin-right: 1em;
  background-color: #EEE;
`

const Title = div`
  padding: .5em 0;
`

const Gradient = div`
  background-image: linear-gradient(90deg,rgba(255,255,255,0),#fff);
  position: absolute;
  right: 0;
  width: 45vw;
  bottom: 0;
  top: 0;
  pointer-events: none;
  opacity: 1;
  transition: opacity 1s ease;
  @media (max-width: 479px) {
    opacity: 0;
  }
`

const wh = (width, height) => ({width, height})

export default ({ projects }) => <Container>{ projects.slice(0,8).map(project => (
  <Project key={project.id}>
    <Link to={`/${friendlyUrl(project.title) != ''? friendlyUrl(project.title) : 'being-time' }`}>
      <Thumbnails>
        {
          project.slides.map(slide => <Thumbnail key={slide.id} style={wh(200/slide.height*slide.width, 200)}><Image style={wh(200/slide.height*slide.width, 200)} imageUrl={slide.file.url} {...wh(200/slide.height*slide.width, 200)}/></Thumbnail>)
        }
     </Thumbnails>
      <Title>{project.title}</Title>
    </Link>
  </Project>
)) }
  <Gradient/>
  </Container>