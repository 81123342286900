import React, { Component } from "react"
import { ApolloProvider } from "react-apollo"
import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { CachePersistor } from "apollo-cache-persist"

const cache = new InMemoryCache()
const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debug: false,
})

const httpLink = new HttpLink({
  uri: "https://api.whitespace.photos/graphql",
})

const client = new ApolloClient({
  link: httpLink,
  cache,
})

export default class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restored: false,
    }
  }

  componentDidMount() {
    persistor
      .restore()
      .then(() => this.setState({ restored: true }))
      .catch(() => this.setState({ restored: true }))
  }

  render() {
    if (!this.state.restored) {
      return <div>...</div>
    } else {
      return (
        <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
      )
    }
  }
}
